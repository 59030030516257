import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import axiosRetry, { exponentialDelay, IAxiosRetryConfig, isNetworkOrIdempotentRequestError } from 'axios-retry'
import createDebug from 'lib/util/createDebug'

interface AxiosRetryRequestConfig extends AxiosRequestConfig {
  'axios-retry': IAxiosRetryConfig
}

interface AxiosRetryError extends AxiosError {
  config: AxiosRetryRequestConfig
}

const debug = createDebug('api')

const client = axios.create({
  baseURL: process.env.KONG_URI
})

function retryCondition(error: AxiosRetryError): boolean {
  const shouldRetry = isNetworkOrIdempotentRequestError(error)
  const url = error.config && error.config.url
  const retryCount = error.config && error.config['axios-retry'] && error.config['axios-retry'].retries
  debug('Received API Error (retry count=%s; retrying=%s): %s %O', retryCount, shouldRetry, url, error)

  return shouldRetry
}

axiosRetry(client, { retries: 4, retryDelay: exponentialDelay, retryCondition })

export default client
