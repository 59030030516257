export interface Self {
  id?: string | null
  trainingAdmin?: boolean | null
  firstName?: string | null
  lastName?: string | null
  email?: string
  loading: boolean
  hash?: string
}

export function isLoggedIn(self: Self) {
  return !!self.id
}

export function isAdmin(self: Self) {
  return !!self.trainingAdmin
}
