import './ErrorPage.scss'

import classNames from 'classnames'
import Button from 'lib/components/Button/Button'
import React, { AnchorHTMLAttributes, HTMLAttributes, ImgHTMLAttributes } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'

function ArticulateLogo(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { className, ...otherProps } = props
  const mergedClassName = classNames('error-page__art-logo', className)
  const [t] = useTranslation()

  return (
    <a className={mergedClassName} href={process.env.WWW_URI} {...otherProps}>
      <img src={`${process.env.CDN_URI}/assets/images/logo.png`} alt={t('errorPages.logoAlt')} />
    </a>
  )
}

function Header(props: HTMLAttributes<HTMLHeadingElement>) {
  const { className, ...otherProps } = props
  const mergedClassName = classNames('error-page__header', className)
  const [t] = useTranslation()

  return (
    <h2 className={mergedClassName} {...otherProps}>
      {t('errorPages.header')}
    </h2>
  )
}

function Description(props: HTMLAttributes<HTMLParagraphElement>) {
  const { children, className, ...otherProps } = props
  const mergedClassName = classNames('error-page__description', className)

  return (
    <p className={mergedClassName} {...otherProps}>
      {children}
    </p>
  )
}

function CustomImage(props: ImgHTMLAttributes<HTMLImageElement>) {
  const { children, className, ...otherProps } = props
  const mergedClassName = classNames('error-page__img', className)

  return (
    <img className={mergedClassName} {...otherProps}>
      {children}
    </img>
  )
}

const BackButton = withRouter(function BackButton(props: RouteComponentProps) {
  const { history, ...otherProps } = props
  const [t] = useTranslation()

  return (
    <Button onClick={() => history.push('/')} {...otherProps}>
      {t('errorPages.button')}
    </Button>
  )
})

function HelpLink(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { className, ...otherProps } = props
  const mergedClassName = classNames('error-page__help', className)
  const [t] = useTranslation()

  return (
    <a className={mergedClassName} href={`${process.env.WWW_URI}/support/contact`} {...otherProps}>
      {t('errorPages.help')}
    </a>
  )
}

function ErrorPage(props: HTMLAttributes<HTMLDivElement>) {
  const { className, children, ...otherProps } = props
  const [t] = useTranslation()
  const mergedClassName = classNames('error-page', className)

  return (
    <div className={mergedClassName} {...otherProps}>
      <Helmet defer={false}>
        <title>{t('errorPages.pageTitle')}</title>
      </Helmet>
      {children}
    </div>
  )
}

ErrorPage.ArticulateLogo = ArticulateLogo
ErrorPage.Header = Header
ErrorPage.Description = Description
ErrorPage.CustomImage = CustomImage
ErrorPage.BackButton = BackButton
ErrorPage.HelpLink = HelpLink

export default ErrorPage
