import './polyfill/ie11'
import './index.scss'
import 'lib/util/datadog'

import { useSubscription } from 'lib/client/360-subscriptions'
import ErrorBoundary from 'lib/components/ErrorPages/ErrorBoundary'
import NotFound from 'lib/components/ErrorPages/NotFound'
import RenderingError from 'lib/components/ErrorPages/RenderingError'
import LaunchDarklyProvider from 'lib/components/LaunchDarklyProvider/LaunchDarklyProvider'
import AdminContext from 'lib/context/AdminContext'
import AuthContext from 'lib/context/AuthContext'
import SubContext from 'lib/context/SubContext'
import useAdmin from 'lib/hooks/useAdmin/useAdmin'
import useAuth from 'lib/hooks/useAuth'
import { refreshSession } from 'lib/hooks/useAuth/session'
import { getUserCountryCookie, saveUserCountryCookie } from 'lib/util/cookie'
import GrowthScript from 'lib/util/growthScript'
import { PATHS } from 'lib/util/routing'
import Section from 'pages/Home/Section'
import Loading from 'pages/Loading/Loading'
import React, { lazy, Suspense, useEffect } from 'react'
import { render } from 'react-dom'
import { Helmet } from 'react-helmet'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'

import i18n from './i18n'

const Home = lazy(() => import(/* webpackChunkName: "Home" */ 'pages/Home/Home'))
const Video = lazy(() => import(/* webpackChunkName: "Video" */ 'pages/Video/Video'))
const WebinarDetail = lazy(() => import(/* webpackChunkName: "WebinarDetail" */ 'pages/WebinarDetail/WebinarDetail'))
const VideoPreview = lazy(() => import(/* webpackChunkName: "VideoPreview" */ 'pages/VideoPreview/VideoPreview'))

function App() {
  const { self, login, logout, getAccessToken, callbackRoute } = useAuth((path, render) => (
    <Route exact path={path} render={render} />
  ))

  const authContext = { self, login, logout, getAccessToken }

  const refreshOktaSession = () => refreshSession(self, logout)

  useEffect(() => {
    document.addEventListener('scroll', refreshOktaSession)
    document.addEventListener('click', refreshOktaSession)
    document.addEventListener('mousedown', refreshOktaSession)
    document.addEventListener('keydown', refreshOktaSession)

    return function cleanup() {
      document.removeEventListener('scroll', refreshOktaSession)
      document.removeEventListener('click', refreshOktaSession)
      document.removeEventListener('mousedown', refreshOktaSession)
      document.removeEventListener('keydown', refreshOktaSession)
    }
  }, [self.id])

  useEffect(() => {
    if (!getUserCountryCookie()) {
      saveUserCountryCookie()
    }
  }, [])

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
      </Helmet>
      <AuthContext.Provider value={authContext}>
        <SubContext.Provider value={useSubscription(getAccessToken, logout, self)}>
          <AdminContext.Provider value={useAdmin(self)}>
            <LaunchDarklyProvider>
              <GrowthScript />
              <Router>
                <ErrorBoundary>
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Redirect exact from="/" to={PATHS.videos} />
                      <Route exact path={PATHS.videos} render={() => <Home section={Section.Videos} />} />
                      <Route exact path={PATHS.webinars} render={() => <Home section={Section.RecordedWebinars} />} />
                      <Route exact path={PATHS.upcoming} render={() => <Home section={Section.UpcomingWebinars} />} />
                      <Route path={`${PATHS.videos}/:id`} component={Video} />
                      <Route path={`${PATHS.webinars}/:id`} component={WebinarDetail} />
                      <Route path={PATHS.videoPreview} component={VideoPreview} />
                      <Route exact path={PATHS.renderingError} component={RenderingError} />
                      {callbackRoute}
                      <NotFound />
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </Router>
            </LaunchDarklyProvider>
          </AdminContext.Provider>
        </SubContext.Provider>
      </AuthContext.Provider>
    </>
  )
}

render(<App />, document.getElementById('app'))
