import './Button.scss'

import classNames from 'classnames'
import React, { ButtonHTMLAttributes, forwardRef, ReactNode, RefObject } from 'react'

import Spinner from '../Spinner/Spinner'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  children?: ReactNode
  disabled?: boolean
  loading?: boolean
  variant?: 'primary' | 'secondary' | 'warning' | 'link'
  size?: 'small' | 'medium'
}

function buildModifierClasses(size: string, variant: string) {
  const variantClass = `three-sixty-button--${variant}`

  return variant === 'link' ? variantClass : `${variantClass} three-sixty-button--${size}`
}

const Button = forwardRef((props: ButtonProps, ref: RefObject<HTMLButtonElement>) => {
  const { className, children, disabled, loading, variant = 'primary', size = 'medium', ...otherProps } = props
  const modifierClasses = buildModifierClasses(size, variant)
  const mergedClassName = classNames('three-sixty-button', className, modifierClasses)

  return (
    <button ref={ref} className={mergedClassName} disabled={disabled || loading} {...otherProps}>
      {loading ? <Spinner /> : children}
    </button>
  )
})

Button.displayName = 'Button'

export default Button
