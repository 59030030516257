import React from 'react'

import datadog from '../../util/datadog'
import GenericError from './GenericError'

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<{children: JSX.Element}, State> {
  public constructor(props: {children: JSX.Element}) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    datadog.addError(error, errorInfo)
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <GenericError />
    }

    return this.props.children
  }
}

export default ErrorBoundary
