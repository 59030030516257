import backoff from '@articulate/funky/lib/backoff'
import { AuthApiError, OAuthError } from '@okta/okta-auth-js'
import { LogoutFunc, SignOutReason } from 'lib/hooks/useAuth'
import { isLoggedIn, Self } from 'lib/util/self'

import { refreshSession as refreshOktaSession } from './okta'

const wait = (time: number) =>
  new Promise(resolve => setTimeout(resolve, time))

const isSessionExpiredError = (error: AuthApiError | OAuthError | Error) => {
  if ('errorCode' in error) {
    return error.errorCode === 'E0000007'
  }

  if ('xhr' in error) {
    error.xhr?.status === 404
  }

  return false
}

const refreshSessionWithBackoff = backoff(
  {
    base: 1000,
    tries: 5,
    when: (error) => !isSessionExpiredError(error)
  },
  refreshOktaSession)

let refreshSessionPromise: Promise<void | object>

export const refreshSession = (self: Self, logout: LogoutFunc) => {
  if (!refreshSessionPromise && isLoggedIn(self)) {
    refreshSessionPromise = refreshSessionWithBackoff()
      .catch((error) => {
        if (isSessionExpiredError(error)) {
          logout(SignOutReason.InvalidSession, 'Okta session expired')
        }
      })
      .finally(async() => {
        await wait(30 * 1000)
        refreshSessionPromise = null
      })
  }
}
