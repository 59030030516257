import './GenericError.scss'

import DisconnectedImg from 'img/error-disconnected.png'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorPage from './ErrorPage'

export default function GenericError() {
  const [t] = useTranslation()

  return (
    <ErrorPage className="generic-error">
      <ErrorPage.ArticulateLogo />
      <ErrorPage.Header />
      <ErrorPage.Description>{t('errorPages.generic.description')}</ErrorPage.Description>
      <ErrorPage.CustomImage src={DisconnectedImg} alt={t('errorPages.imgAlt')} />
      <ErrorPage.BackButton />
      <ErrorPage.HelpLink />
    </ErrorPage>
  )
}
