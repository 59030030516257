import './Loading.scss'

import Spinner from 'lib/components/Spinner/Spinner'
import React from 'react'

export default function Loading(): JSX.Element {
  return (
    <div className="loading-page">
      <Spinner size="medium" />
    </div>
  )
}
