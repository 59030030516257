import axios, { AxiosError } from 'axios'
import { GetAccessTokenFunc } from 'lib/hooks/useAuth'
import createDebug from 'lib/util/createDebug'
import { useEffect, useReducer } from 'react'

import client from './axios'

const CancelToken = axios.CancelToken
const debug = createDebug('api')

interface ApiState<T> {
  data: T | null
  error: AxiosError | null
  loading: boolean
}

enum ActionType {
  FetchStart = 'FETCH_STARTT',
  FetchComplete = 'FETCH_COMPLETE',
  FetchError = 'FETCH_ERROR',
  Reset = 'RESET',
}

interface ApiAction<T> {
  type: ActionType
  payload?: T
  error?: AxiosError
}

function reducer<T>(state: ApiState<T>, action: ApiAction<T>): ApiState<T> {
  switch (action.type) {
    case ActionType.FetchStart:
      return { data: state.data, error: null, loading: true }
    case ActionType.FetchComplete:
      return { data: action.payload, error: null, loading: false }
    case ActionType.FetchError:
      return { data: state.data, error: action.error, loading: false }
    case ActionType.Reset:
      return { data: null, error: null, loading: false }
  }
}

function shouldUseFallbackUrl(err: AxiosError, fallBackUrl?: string) {
  return err.response && err.response.status === 404 && fallBackUrl
}

function buildHeaders(rawAccessToken: string) {
  return rawAccessToken
    ? {
      Accept: 'application/json',
      Authorization: `Bearer ${rawAccessToken}`
    }
    : null
}

export default function useApi<T>(getAccessToken: GetAccessTokenFunc, url?: string, fallBackUrl?: string): ApiState<T> {
  const [state, dispatch] = useReducer(reducer, { data: null, error: null, loading: true })

  useEffect(() => {
    const source = CancelToken.source()

    async function fetchData() {
      dispatch({ type: ActionType.FetchStart })
      let accessToken
      try {
        accessToken = await getAccessToken()
      } catch (err) {
        // User's session may be expired on id server; just
        //   make request wihout access token
      }
      try {
        const response = await client(url, {
          cancelToken: source.token,
          headers: buildHeaders(accessToken)
        })
        dispatch({ type: ActionType.FetchComplete, payload: response.data })
      } catch (err) {
        if (axios.isCancel(err)) {
          return
        }
        if (shouldUseFallbackUrl(err, fallBackUrl)) {
          debug('using fallback url', { url, fallBackUrl })
          try {
            const response = await client(fallBackUrl, {
              cancelToken: source.token,
              headers: buildHeaders(accessToken)
            })
            dispatch({ type: ActionType.FetchComplete, payload: response.data })
          } catch (err) {
            if (axios.isCancel(err)) {
              return
            }

            return dispatch({ type: ActionType.FetchError, error: err })
          }
        } else {
          return dispatch({ type: ActionType.FetchError, error: err })
        }
      }
    }

    if (url) {
      fetchData()
    } else {
      dispatch({ type: ActionType.Reset })
    }

    return function cleanup() {
      source.cancel()
    }
  }, [fallBackUrl, url, getAccessToken])

  return state as ApiState<T>
}
