import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: {
      header: {
        accountMenu: {
          account: 'Account',
          signOut: 'Sign Out'
        },
        avatar: {
          alt: 'Avatar for {{email}}'
        },
        admin: {
          tooltip: 'Toggle Admin UI'
        },
        featureDisabled: '{{ feature }} is disabled for your Articulate 360 subscription. Contact your subscription administrator for more\ninformation.',
        startFreeTrial: 'Start Free Trial',
        subscribe: 'Subscribe'
      },
      home: {
        admin: {
          actionDialog: {
            save: 'Save',
            delete: 'Delete',
            cancel: 'Cancel'
          },
          controlButton: {
            add: 'Add',
            edit: 'Edit',
            delete: 'Delete',
            duplicate: 'Duplicate'
          }
        },
        helmet: {
          reach: {
            keywords:
              'e-learning developer, live e-learning training, e-learning development, instructional design, webinars, tutorials, Articulate 360, Articulate 360 Training, Reach 360'
          },
          rise: {
            keywords:
              'e-learning developer, live e-learning training, e-learning development, instructional design, webinars, tutorials, Articulate 360, Articulate 360 Training, Rise 360'
          },
          storyline: {
            keywords:
              'e-learning developer, live e-learning training, e-learning development, instructional design, webinars, tutorials, Articulate 360, Articulate 360 Training, Storyline 360'
          },
          more: {
            keywords:
              'e-learning developer, live e-learning training, e-learning development, instructional design, webinars, tutorials, Articulate 360, Articulate 360 Training'
          }
        },
        title: 'Build your skills with Articulate\u00A0360\u00A0Training',
        subtitle: 'Watch our friendly video guides and webinars with e-learning experts to grow your skills',
        productCategoryFilter: {
          message: 'What would you like to learn about?',
          more: 'More'
        },
        search: {
          placeholder: 'Search',
          results: 'Results for “{{- search}}”...',
          noResults: 'No results for “{{- search}}”...',
          clearSearch: 'Clear search'
        },
        subscribeDialog: {
          header: 'Subscribe to Articulate\u00A0360',
          nonSeatAdminHeader: 'Add yourself as a user',
          body:
            'This training is available for Articulate\u00A0360 subscribers and trialers. Start a free trial or buy now to get unlimited access to it.',
          expiredTrialBody: 'Your trial has expired. Buy an Articulate\u00A0360 subscription to access this content.',
          expiredSubBody:
            'Want to continue using Articulate\u00A0360\u00A0Training? Buy an Articulate\u00A0360 subscription.',
          nonSeatHoldingAdminActiveTrialBody:
            'You\'re currently an admin on this trial account. To use Articulate\u00A0360 apps and resources, please add yourself to a seat on your trial account.',
          nonSeatHoldingAdminBody:
            'You\'re currently an admin on this account. To use Articulate\u00A0360 apps and resources, please add yourself to a seat on your account.',
          cancelButton: 'Cancel',
          startTrialButton: 'Start Free Trial',
          buyButton: 'Buy Now',
          signInButton: 'Sign In',
          addNowButton: 'Add Now'
        },
        tabGroup: {
          videos: 'Feature Tutorials',
          recordedWebinars: 'On-Demand Training',
          upcomingWebinars: 'Upcoming Webinars',
          upcomingWebinarsShort: 'Webinars'
        },
        sections: {
          zeroState: {
            link: 'Clear\u00A0filters'
          }
        },
        videosSection: {
          admin: {
            deleteDialog: {
              header: 'Delete video',
              body: 'Are you sure you want to delete this video?',
              apiError: 'Something went wrong.'
            },
            editDialog: {
              header: 'Edit video',
              categories: 'Categories',
              makePublic: 'Make public',
              publish: 'Publish',
              categoryError: 'Published videos must have a category',
              nameError: 'Name cannot be blank',
              placeholder: 'Video name',
              apiError: 'Something went wrong.',
              thumbnail: {
                customize: 'Customize thumbnail',
                delete: 'Delete',
                replace: 'Replace',
                upload: 'Upload'
              },
              url: 'URL',
              autoGenerate: 'Auto-generate',
              path: 'Path',
              uniqueError: 'Error generating URL for video: A video with that URL already exists.',
              warning: 'Changing the name or category will generate a new URL for the video.'
            },
            uploadThumbnailDialog: {
              replace: 'Replace custom thumbnail',
              upload: 'Upload custom thumbnail',
              selectFile: 'Select file'
            },
            videoTile: {
              processing: 'Video still processing',
              refresh: 'Refresh page'
            }
          },
          header: {
            title: '{{category}} Feature Tutorials',
            subtitle: 'Short video guides to help you learn quickly.'
          },
          helmet: {
            rise: {
              title: 'Watch Rise\u00A0360 Tutorials - Articulate\u00A0360\u00A0Training',
              description: 'Build your Rise\u00A0360 skills with tutorials crafted by e-learning experts.'
            },
            storyline: {
              title: 'Watch Storyline\u00A0360 Tutorials  - Articulate\u00A0360\u00A0Training',
              description: 'Build your Storyline\u00A0360 skills with tutorials crafted by e-learning experts.'
            }
          },
          zeroState: {
            message: 'No videos available with your current filters.'
          }
        },
        upcomingWebinarsSection: {
          admin: {
            addButton: {
              tooltip: 'Add an upcoming webinar'
            },
            addDialog: {
              publish: 'Publish',
              makePublic: 'Make public',
              apiError: 'Something went wrong.',
              title: 'Schedule an upcoming webinar',
              editTitle: 'Edit upcoming webinar',
              titlePlaceholder: 'Title',
              descriptionPlaceholder: 'Description',
              datePlaceholder: 'Date and time',
              presenterPlaceholder: 'Presenter',
              registrationPlaceholder: 'Registration Link',
              pastDateError: 'Date cannot be in the past.',
              validationError: 'One or more required fields are missing.',
              selectPlaceholder: '-- Select a Recorded Webinar --'
            },
            deleteDialog: {
              header: 'Delete upcoming webinar',
              body: 'Are you sure you want to delete this upcoming webinar?',
              apiError: 'Something went wrong.'
            },
            hidePastWebinars: {
              text: 'Hide past upcoming webinars'
            }
          },
          zeroState: {
            message: 'No upcoming webinars available with your current filters.'
          },
          header: {
            title: '{{category}} Upcoming Webinars',
            subtitle: 'Sign up for any of the upcoming live webinars below.'
          },
          helmet: {
            reach: {
              title: 'Register for Upcoming Reach\u00A0360 Webinars - Articulate\u00A0360\u00A0Training',
              description:
                'Sign up for live webinars led by industry experts that will help grow your e-learning skills with Reach\u00A0360.'
            },
            rise: {
              title: 'Register for Upcoming Rise\u00A0360 Webinars - Articulate\u00A0360\u00A0Training',
              description:
                'Sign up for live webinars led by industry experts that will help grow your e-learning skills with Rise\u00A0360.'
            },
            storyline: {
              title: 'Register for Upcoming Storyline\u00A0360 Webinars - Articulate\u00A0360\u00A0Training',
              description:
                'Sign up for live webinars led by industry experts that will help grow your e-learning skills with Storyline\u00A0360.'
            },
            more: {
              title: 'Register for Upcoming E-Learning Webinars - Articulate\u00A0360\u00A0Training',
              description:
                'Sign up for live webinars led by industry experts that will help grow your e-learning skills.'
            }
          },
          upcomingWebinar: {
            dateFormat: 'MMM d, yyyy',
            hourFormat: 'haaa',
            searchFormat: 'EEEE MMMM do yyyy h aaa',
            time: '{{startTime}} \u2013 {{endTime}} {{timezone}}',
            signUpButton: 'Sign\u00A0Up'
          }
        },
        recordedWebinarsSection: {
          admin: {
            addButton: {
              tooltip: 'Add a recorded webinar'
            },
            addDialog: {
              apiError: 'Something went wrong.',
              categories: 'Categories',
              title: 'Add recorded webinar',
              editTitle: 'Edit recorded webinar',
              titlePlaceholder: 'Title',
              descriptionPlaceholder: 'Description',
              morePlaceholder: 'More',
              validationError: 'One or more required fields are missing.',
              categoryMissing: 'At least one category must be selected.',
              noSkillLevel: '--No skill level --',
              noSeries: '--No series selected --',
              publish: 'Publish',
              makePublic: 'Make public',
              url: 'URL',
              autoGenerate: 'Auto-generate',
              path: 'Path',
              uniqueError:
                'Error generating URL for recorded webinar: A recorded webinar with that URL already exists.',
              warning: 'Changing the title will generate a new URL for the recorded webinar.'
            },
            deleteDialog: {
              header: 'Delete recorded webinar',
              body: 'Are you sure you want to delete this recorded webinar?',
              apiError: 'Something went wrong.',
              conflictError: 'Cannot delete while linked upcoming webinars exist'
            }
          },
          sort: {
            popular: 'Popular',
            recent: 'Recent',
            title: 'Title',
            titleAttr: 'Sort by: {{sort}}'
          },
          zeroState: {
            message: 'No recorded webinars available with your current filters.'
          },
          header: {
            title: '{{category}} Training',
            subtitle: 'Take on-demand training or sign up for an <0><0>upcoming webinar.</0></0>'
          },
          helmet: {
            reach: {
              title: 'Watch Reach\u00A0360 Webinars - Articulate\u00A0360\u00A0Training',
              description:
                'Watch webinars led by industry experts that will help grow your e-learning skills with Reach\u00A0360.'
            },
            rise: {
              title: 'Watch Rise\u00A0360 Webinars - Articulate\u00A0360\u00A0Training',
              description:
                'Watch webinars led by industry experts that will help grow your e-learning skills with Rise\u00A0360.'
            },
            storyline: {
              title: 'Watch Storyline\u00A0360 Webinars  - Articulate\u00A0360\u00A0Training',
              description:
                'Watch webinars led by industry experts that will help grow your e-learning skills with Storyline\u00A0360.'
            },
            more: {
              title: 'Watch E-Learning Webinars - Articulate\u00A0360\u00A0Training',
              description:
                'Watch recorded webinars led by industry experts that will help grow your e-learning skills.'
            }
          }
        },
        series: {
          admin: {
            addDialog: {
              apiError: 'Something went wrong.',
              uniqueError: 'A series already exists with that title.',
              title: 'Add series',
              editTitle: 'Edit series',
              publish: 'Publish',
              makePublic: 'Make public',
              titlePlaceholder: 'Title'
            },
            deleteDialog: {
              header: 'Delete series',
              body: 'Are you sure you want to delete this series?',
              constraintError: 'Cannot delete while linked recorded webinars exist',
              apiError: 'Something went wrong.'
            }
          },
          all: {
            name: 'All',
            longName: 'All Series'
          },
          more: 'More...'
        },
        skillLevels: {
          all: 'All',
          allLong: 'All skill levels',
          beginner: 'Beginner',
          advanced: 'Advanced',
          title: 'Skill level: {{skillLevel}}'
        },
        startTimes: {
          all: 'All',
          allLong: 'All start times',
          morning: 'Morning',
          afternoon: 'Afternoon',
          evening: 'Evening',
          title: 'Start time: {{startTime}}'
        }
      },
      banners: {
        cookie: {
          text: 'This site uses cookies. By continuing to browse the site you consent to our use of cookies.',
          link: 'Learn more here'
        }
      },
      icons: {
        liveBadge: {
          text: 'Live'
        }
      },
      webinarDetail: {
        admin: {
          confirmPublishDialog: {
            publish: {
              title: 'Confirm publish',
              body: 'Are you sure you want to publish <0><0>{{title}}</0></0>?',
              button: 'Publish'
            },
            unpublish: {
              title: 'Confirm unpublish',
              body: 'Are you sure you want to unpublish <0><0>{{title}}</0></0>?',
              button: 'Unpublish'
            }
          },
          detailDescription: {
            editDialog: {
              title: 'Edit detailed description',
              apiError: 'Something went wrong',
              validationError: 'Description cannot be blank'
            }
          },
          downloadsSection: {
            editDialog: {
              title: 'Edit download',
              placeholder: 'Display name'
            }
          },
          objectivesSection: {
            addDialog: {
              title: 'Update objectives',
              titlePlaceholder: 'Title',
              h: 'H',
              hours: 'Hours',
              m: 'M',
              minutes: 'Minutes',
              s: 'S',
              seconds: 'Seconds',
              zeroState: 'No objectives',
              preview: 'Preview'
            },
            invalidTime: 'Times must be non-negative and less than video duration'
          },
          publishButton: {
            publish: 'Publish',
            unpublish: 'Unpublish'
          },
          recommendationsSection: {
            addDialog: {
              title: 'Add recommended webinar',
              placeholder: 'Webinar ID or URL slug'
            }
          },
          resourcesSection: {
            addDialog: {
              addTitle: 'Add resource',
              editTitle: 'Edit resource',
              namePlaceholder: 'Name',
              urlPlaceholder: 'Url'
            }
          },
          richTextSection: {
            saveButton: 'Save rich text section'
          }
        },
        helmet: {
          titleSuffix: '- Articulate\u00A0360\u00A0Training',
          keywords:
            'e-learning developer, live e-learning training, e-learning development, instructional design, webinars, tutorials, Articulate 360, Articulate 360 Training'
        },
        downloadsSection: {
          adminPlaceholder: 'Add downloads',
          header: 'Downloads'
        },
        objectivesSection: {
          adminPlaceholder: 'Add objectives',
          header: 'Objectives'
        },
        recommendationsSection: {
          adminPlaceholder: 'Add recommendation',
          header: 'Recommended Training'
        },
        resourcesSection: {
          adminPlaceholder: 'Add resources',
          header: 'Resources'
        },
        videoSection: {
          adminPlaceholder: 'Upload a video, or refresh page to update progress.'
        }
      },
      video: {
        helmet: {
          titleSuffix: '- Articulate\u00A0360\u00A0Training',
          description:
            'Browse training videos led by industry experts. Learn how to get started fast in Articulate\u00A0360, build better e-learning courses, and more.',
          keywords:
            'e-learning developer, live e-learning training, e-learning development, instructional design, webinars, tutorials, Articulate 360, Articulate 360 Training'
        }
      },
      admin: {
        deleteDialog: {
          title: 'Confirm delete',
          body: 'Are you sure you want to delete <0><0>{{name}}</0></0>?'
        },
        genericError: 'An error occurred',
        uploadVideoButton: {
          title: 'Upload Video',
          updateTitle: 'Update Video'
        },
        uploadVideoDialog: {
          title: 'Upload a video',
          updateTitle: 'Update video',
          chooseVideoFile: 'Choose a video file',
          inputOptions: 'Input Options',
          startTime: 'Start Time',
          updateButton: 'Update Video',
          uploadButton: 'Upload Video',
          saving: 'Please wait while video uploads',
          success: 'Video may take several minutes to process. Please check back later.',
          uniqueError: 'Error generating URL for video: A video with that URL already exists.',
          captions: {
            header: 'Add Captions (vtt)',
            en: 'Choose English file',
            fr: 'Choose French file',
            de: 'Choose German file',
            es: 'Choose Spanish file'
          }
        }
      },
      productNames: {
        reach: 'Reach\u00A0360',
        rise: 'Rise\u00A0360',
        storyline: 'Storyline\u00A0360',
        training: 'Articulate\u00A0360\u00A0Training',
        review: 'Review\u00A0360',
        contentLibrary: 'Content\u00A0Library\u00A0360',
        threeSixty: 'Articulate\u00A0360'
      },
      errorPages: {
        header: 'That didn\'t work',
        logoAlt: 'Articulate Logo',
        imgAlt: 'Error image',
        button: 'Back to Articulate\u00A0360\u00A0Training',
        help: 'Need help?',
        pageTitle: 'Articulate - That didn\'t work',
        generic: {
          description: 'We ran into an error while we were looking for the next page. Please try again.'
        },
        notFound: {
          description: 'We tried, but we can\'t find the page you\'re looking for.'
        }
      },
      footer: {
        privacy: 'Privacy',
        faqs: 'FAQs',
        support: 'Support',
        terms: 'Terms',
        cookiePreferences: 'Cookie Preferences'
      },
      videoPlayer: {
        fallback: 'Your browser does not support HTML5 video.',
        captions: {
          de: 'German',
          en: 'English',
          es: 'Spanish',
          fr: 'French'
        }
      },
      trainWithReach360: {
        header: 'Train with Reach 360',
        body: 'We`re taking you to Reach 360 so you can explore training as a learner.',
        buttons: {
          continue: 'Continue<0>(open in a new tab)</0>'
        }
      },
      trainWithReach360Error: {
        header: 'Oops, that didn\'t work',
        body: 'Please try again, and <1>contact support</1> if you need help.',
        buttons: {
          close: 'Close'
        }
      }
    }
  }
}

i18n.use(initReactI18next).init({
  resources: resources,
  lng: 'en'
})

export default i18n
