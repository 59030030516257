import { OktaAuth, SessionObject, TokenResponse, Tokens } from '@okta/okta-auth-js'
import { reportError } from 'lib/util/errorHandler'
import { v4 as uuid } from 'uuid'
const responseType = ['id_token', 'token']
const scopes = ['openid', 'profile_name', 'training_admin', 'email', 'user_subscription']

const CALLBACK_PATH = '/auth-callback'

const authClient = new OktaAuth({
  ignoreSignature: true,
  issuer: process.env.OIDC_ISSUER_URI,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}${CALLBACK_PATH}`,
  pkce: false,
  tokenManager: {
    expireEarlySeconds: 60
  }
})

authClient.tokenManager.on('error', (err: Error) => reportError(err, 'Error from tokenManager'))

let redirectingForLogin = false

const endDateKey = 'endDate'

const getSavedEndDate = () => {
  const storedDate = window.localStorage.getItem(endDateKey)

  return storedDate ? new Date(storedDate) : null
}

const normalizeDate = (date: Date) => new Date(new Date(date).setHours(0, 0, 0, 0)).toISOString()

const saveEndDate = (date: Date) => window.localStorage.setItem(endDateKey, normalizeDate(date))

const signIn = (currentPath: string) => {
  if (redirectingForLogin) {
    return
  }

  redirectingForLogin = true

  const params = {
    responseType,
    state: uuid(),
    scopes
  }

  window.localStorage.setItem(params.state, currentPath)

  return authClient.token.getWithRedirect(params)
}

let signInNoPromptPromise: Promise<TokenResponse> = null

const signInNoPrompt = () => {
  if (!signInNoPromptPromise) {
    signInNoPromptPromise = authClient.token
      .getWithoutPrompt({ responseType, scopes })
      .finally((): void => (signInNoPromptPromise = null))
  }

  return signInNoPromptPromise
}

const setSessionEndDate = () => {
  if (getSavedEndDate()) {
    return
  }

  return authClient.session.get().then((session: SessionObject) =>
    // the SessionObject type doesn't have it on its interface, but I verified
    // via testing that the `expiresAt` property does exist on the object that is coming in here
    saveEndDate(((session as unknown) as { expiresAt: Date }).expiresAt)
  )
}

const isSignoutExpected = () => {
  const endDate = getSavedEndDate()

  return !endDate || endDate.getTime() - new Date().setHours(0, 0, 0, 0) <= 0
}

const clearSessionEndDate = () => window.localStorage.removeItem(endDateKey)

const storeTokens = (tokens: Tokens) => {
  authClient.tokenManager.add('idToken', tokens.idToken)
  authClient.tokenManager.add('accessToken', tokens.accessToken)
}

const refreshSession = () => authClient.session.refresh().then((session: SessionObject) =>
  saveEndDate(((session as unknown) as { expiresAt: Date }).expiresAt)
)

export {
  authClient,
  CALLBACK_PATH,
  clearSessionEndDate,
  isSignoutExpected,
  refreshSession,
  setSessionEndDate,
  signIn,
  signInNoPrompt,
  storeTokens
}
