import { AccessToken, Token, TokenResponse } from '@okta/okta-auth-js/lib/types'
import jwtDecode from 'jwt-decode'
import { identify } from 'lib/util/analytics'
import { events, track } from 'lib/util/analytics'
import createDebug from 'lib/util/createDebug'
import { reportError } from 'lib/util/errorHandler'
import { Self } from 'lib/util/self'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import AuthCallback from './AuthCallback'
import { getSessionIndicatorCookie, removeSessionIndicatorCookie, setSessionIndicatorCookie } from './cookie'
import { isLoginRequiredError, isNotFoundError } from './errors'
import {
  authClient,
  CALLBACK_PATH,
  clearSessionEndDate,
  isSignoutExpected,
  setSessionEndDate,
  signIn,
  signInNoPrompt,
  storeTokens
} from './okta'
import { OktaError } from './types'

const debug = createDebug('auth')

export type GetAccessTokenFunc = ()=> Promise<string>
export type LogoutFunc = (reason: SignOutReason, message?: string)=> void
export enum SignOutReason {
  UserSignOut = 'UserSignOut',
  NoSessionIndicator = 'NoSessionIndicator',
  StatusError = 'StatusError',
  RoleError = 'RoleError',
  RefreshError = 'RefreshError',
  SessionMismatch = 'SessionMismatch',
  InvalidSession = 'InvalidSession'
}
type RenderFunc = ()=> ReactNode
type CallBackRouteGenerator = (path: string, render: RenderFunc)=> ReactNode

const getTokenFromManager = <T extends Token>(tokenKey: string) => {
  return authClient.tokenManager.get(tokenKey).then(token => {
    if (!token) {
      throw new Error('Token not present or unable to refresh expired token')
    }

    if (authClient.tokenManager.hasExpired(token)) {
      return authClient.tokenManager.renew(tokenKey).then(newToken => {
        if (!newToken) {
          throw new Error('Error while renewing token')
        }

        return newToken as T
      })
    }

    return token as T
  })
}

function handleSessionNotFound(error: OktaError) {
  if (isNotFoundError(error)) {
    debug('Swallowing session not found error ^^')

    return
  }
  throw error
}

function hasSessionIndicator() {
  return getSessionIndicatorCookie() !== null
}

function login() {
  debug('Logging in via Okta redirect...')
  signIn(window.location.pathname + window.location.search + window.location.hash)
}

function clearSession(reason: SignOutReason, message: string) {
  debug('Clearing user session')

  if (reason !== SignOutReason.UserSignOut && !isSignoutExpected()) {
    track(
      events.unexpectedLogout,
      {
        reason,
        message
      },
      async() => (await getTokenFromManager<AccessToken>('accessToken')).accessToken,
      false // always send no matter if the user is admin or not
    )
  }

  removeSessionIndicatorCookie()
  authClient.tokenManager.clear()
  clearSessionEndDate()
}

export default function useAuth(callbackRouteGenerator: CallBackRouteGenerator) {
  const [self, setSelf] = useState<Self>({ id: null, loading: true })

  const logout = useCallback((reason: SignOutReason, message?: string) => {
    debug('Logging out')
    clearSession(reason, message)

    return authClient
      .revokeAccessToken()
      .then(() => authClient.closeSession())
      .catch(handleSessionNotFound)
      .catch((error: Error) => reportError(error, 'Error signing out'))
      .then(() => setSelf({ id: null, loading: false }))
  }, [])

  const setupLocalSession = useCallback((tokenResponse: TokenResponse) => {
    storeTokens(tokenResponse.tokens)
    setSessionEndDate()
    const { idToken, accessToken } = tokenResponse.tokens
    const { first_name: firstName, last_name: lastName, email, launchdarkly } = jwtDecode(idToken.idToken)
    const { aid: id, training_admin: trainingAdmin } = jwtDecode(accessToken.accessToken)
    debug(`Setting up user session for ${id}`)
    setSessionIndicatorCookie(id)
    setSelf({ id, trainingAdmin, firstName, lastName, email, loading: false, hash: launchdarkly })
    identify(accessToken.accessToken)

    return accessToken.accessToken
  }, [])

  const refreshTokens = useCallback(() => {
    debug('Refreshing tokens from Okta')

    return signInNoPrompt()
      .then(setupLocalSession)
      .catch((error: OktaError) => {
        if (isLoginRequiredError(error)) {
          debug('Swallowing login required error ^^')
        } else {
          debug('Reporting unexpected error:', error)
          reportError(error, 'Error setting up local session')
        }
        debug('Failed to refresh tokens; logging out', error)

        return logout(SignOutReason.RefreshError, `Failed to refresh tokens from Okta: ${error}`).then(
          () => null as string
        )
      })
  }, [setupLocalSession, logout])

  const getAccessToken = useCallback(() => {
    debug('Getting (existing if present) access token')

    return getTokenFromManager<AccessToken>('accessToken')
      .then((token: AccessToken) => token.accessToken)
      .catch(() => refreshTokens())
  }, [refreshTokens])

  function hasMatchingUserIds() {
    return getSessionIndicatorCookie() === self.id
  }

  function checkSessionOnFocus() {
    debug('Checking session on focus')
    if (self.loading) {
      return
    }
    if (!self.id && !hasSessionIndicator()) {
      debug('No user id + no session indicator => nothing to do')

      return
    }
    if (self.id && hasMatchingUserIds()) {
      debug('User id matches session indicator => nothing to do')

      return
    }
    if (self.id && !hasSessionIndicator()) {
      const message = 'Has user id, but no indicator cookie; logging out and reloading page'
      debug(message)

      return logout(SignOutReason.SessionMismatch, message).then(() => window.location.reload())
    }
    debug('Catch-call case: reloading page', {
      selfLoading: self.loading,
      selfId: self.id,
      hasSessionIndicator: hasSessionIndicator(),
      hasMatchingUserIds: hasMatchingUserIds()
    })
    authClient.tokenManager.clear()
    window.location.reload()
  }

  useEffect(() => {
    if (window.location.pathname === CALLBACK_PATH) {
      debug('useEffect: Callback path, nothing to do')

      return
    } else if (hasSessionIndicator()) {
      debug('useEffect: Has session indicator; refreshing tokens')
      refreshTokens()
    } else {
      debug('useEffect: No session indicator; clearing local session')
      clearSession(SignOutReason.NoSessionIndicator, 'No session indicator in on application load')
      setSelf({ id: null, loading: false })
    }
  }, [refreshTokens])

  useEffect(() => {
    window.addEventListener('focus', checkSessionOnFocus)

    return function cleanup() {
      window.removeEventListener('focus', checkSessionOnFocus)
    }
  })

  const callbackRoute = callbackRouteGenerator(CALLBACK_PATH, () => (
    <AuthCallback setupLocalSession={setupLocalSession} />
  ))

  return { self, login, logout, getAccessToken, callbackRoute }
}
