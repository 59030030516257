import { useSubscription } from 'lib/client/360-subscriptions'
import AuthContext from 'lib/context/AuthContext'
import { useCallback, useContext, useEffect } from 'react'
import React from 'react'

import window from './window'

export default function GrowthScript() {
  const { self, getAccessToken, logout } = useContext(AuthContext)
  const { subscription } = useSubscription(getAccessToken, logout, self)

  const GROWTH_SDK_URI = process.env.GROWTH_SDK_URI

  const initializeGrowthScript = useCallback(async() => {
    if (subscription && self.id) {

      const initArgs = {
        env: process.env.SERVICE_ENV,
        product: process.env.SERVICE_PRODUCT,
        region: process.env.AWS_REGION
      }

      window.growth('init', initArgs)

      window.growth('identify', {
        subscription_id: subscription?.subscription_id,
        user_id: self.id
      })
    }
  }, [subscription, self.id])

  const injectScript = useCallback(() => {
    const script = document.createElement('script')
    script.async = true
    script.id = 'growth-script'
    script.src = `${GROWTH_SDK_URI}`
    script.onload = () => {
      initializeGrowthScript()
    }

    document.body.appendChild(script)
  }, [initializeGrowthScript])

  useEffect(() => {
    if (!window.growth && subscription && self) {
      injectScript()
    }
  }, [injectScript])

  return (
    <></>
  )
}
