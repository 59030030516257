import { AccessToken } from '@okta/okta-auth-js/lib/types'
import { events, track } from 'lib/util/analytics'
import { getCookie, removeCookie, setPersistantCookie } from 'lib/util/cookie'

import { authClient } from './okta'

// TODO use process.env.SESSION_COOKIE_NAME after env var is set
const SESSION_COOKIE_NAME = '_articulate_user_id'

const getAccessToken = () =>
  authClient.tokenManager
    .get('accessToken')
    .then((token: AccessToken) => token.accessToken)
    .catch(() => ({})) as Promise<string>

export function setSessionIndicatorCookie(id: string) {
  track(events.signedIn, { category: 'auth', userId: id }, getAccessToken, false)

  setPersistantCookie(SESSION_COOKIE_NAME, id)
}

export function getSessionIndicatorCookie() {
  return getCookie(SESSION_COOKIE_NAME)
}

export function removeSessionIndicatorCookie() {
  const sessionCookieContents = getSessionIndicatorCookie()
  track(events.removingSessionCookie, { category: 'auth', userId: sessionCookieContents }, getAccessToken, false)

  removeCookie(SESSION_COOKIE_NAME)
}
