import datadog from './datadog'

function reportError(error: Error, customMessage: string, metaData?: object): void {
  if (customMessage) {
    error.message = `${customMessage}: ${error.message}`
  }

  datadog.addError(error, metaData)
}

export { reportError }
