export interface UserSubStatus {
  subscription?: Subscription
  adminRole?: Role
  loading: boolean
}

export interface Features {
  enable_review: boolean
  enable_rise: boolean
}

export type Plan = 'team' | 'trial' | 'individual'
export type Status = 'active' | 'inactive'

export interface Subscription {
  start_date: Date
  end_date: Date
  is_retrial_eligible: boolean
  is_team_account: boolean
  is_team_admin: boolean
  plan: Plan
  status: Status
  subscription_id: string
  features: Features
}

export interface Role {
  id: string
  plan: Plan
  state: Status
}

export function isInactiveTrial(userSubStatus: UserSubStatus): boolean {
  const { adminRole, subscription } = userSubStatus

  if (adminRole) {
    return adminRole.state === 'inactive' && adminRole.plan === 'trial'
  }

  return (
    subscription &&
    subscription.plan === 'trial' &&
    subscription.status === 'inactive' &&
    subscription.is_retrial_eligible === false
  )
}

export function isPaid(userSubStatus: UserSubStatus): boolean {
  const { adminRole, subscription } = userSubStatus

  const paidPlans = ['team', 'individual']

  if (adminRole) {
    return paidPlans.indexOf(adminRole.plan) !== -1
  }

  return subscription && paidPlans.indexOf(subscription.plan) !== -1
}

export function isInactiveSub(userSubStatus: UserSubStatus): boolean {
  const { adminRole, subscription } = userSubStatus

  if (!isPaid(userSubStatus)) {
    return false
  }

  if (adminRole) {
    return adminRole.state === 'inactive'
  }

  return subscription && subscription.status === 'inactive'
}

export function isInactive(userSubStatus: UserSubStatus): boolean {
  const { adminRole, subscription } = userSubStatus
  if (adminRole) {
    return adminRole.state === 'inactive'
  }

  return subscription && subscription.status === 'inactive'
}

export function isNotRetrialEligible(userSubStatus: UserSubStatus): boolean {
  const { adminRole, subscription } = userSubStatus
  if (adminRole) {
    return true
  }

  return subscription && !subscription.is_retrial_eligible
}

export function isActiveSub(userSubStatus: UserSubStatus): boolean {
  const { subscription } = userSubStatus

  return subscription && subscription.status === 'active'
}

export function isReviewEnabled(userSubStatus: UserSubStatus): boolean {
  const { subscription } = userSubStatus

  return subscription ? subscription.features.enable_review !== false : true
}

export function isRiseEnabled(userSubStatus: UserSubStatus): boolean {
  const { subscription } = userSubStatus

  return subscription ? subscription.features.enable_rise !== false : true
}

export function isNonSeatHoldingAdmin(userSubStatus: UserSubStatus): boolean {
  const { adminRole, subscription } = userSubStatus

  return !subscription && adminRole && adminRole.state === 'active'
}

export function isTrial(role: Role) {
  return role.plan === 'trial'
}

export function isActiveTrial(role: Role) {
  return isTrial(role) && role.state === 'active'
}
