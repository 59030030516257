import { History, Location } from 'history'

export type KeyVal = [string, string?]

export function buildQueryString(location: Location, keyVals: KeyVal[]) {
  const params = new URLSearchParams(location.search)
  keyVals.forEach(([key, value]) => {
    if (value) {
      params.set(key, value)
    } else {
      params.delete(key)
    }
  })
  const queryString = params.toString()
  if (queryString) {
    return `?${queryString}`
  }

  return ''
}

export function setParamInUrl(key: string, value: string, history: History, location: Location) {
  const params = new URLSearchParams(location.search)
  params.set(key, value)
  history.replace({ ...location, search: `?${params.toString()}` })
}

export const QUERY_KEYS = {
  category: 'category',
  series: 'series',
  skill: 'skill',
  start: 'start',
  sort: 'sort',
  search: 'search'
}

export const FILTER_QUERY_KEYS = [
  QUERY_KEYS.series,
  QUERY_KEYS.skill,
  QUERY_KEYS.start,
  QUERY_KEYS.search,
  QUERY_KEYS.sort
].map(key => [key] as KeyVal)

export const QUERY_VALUES = {
  // Product categories (category)
  reach: 'reach',
  rise: 'rise',
  more: 'more',
  storyline: 'storyline',
  // Skill levels (skill)
  beginner: 'beginner',
  advanced: 'advanced',
  // Start times (start)
  morning: 'morning',
  afternoon: 'afternoon',
  evening: 'evening',
  // Recorded Webinars Sort (sort)
  popular: 'popular',
  recent: 'recent',
  title: 'title'
}

export const PATHS = {
  renderingError: '/errors/rendering',
  webinars: '/webinars',
  upcoming: '/upcoming',
  videos: '/videos',
  videoPreview: '/video-preview'
}

export const UPCOMING_WEBINAR_SIGN_UP_ID = 'sign-up'
