import { datadogRum, RumErrorEvent, RumResourceEvent } from '@datadog/browser-rum'

const redactSensitiveInfo = (url: string) =>
  url
    .replace(/id_token=[^&]*/, 'id_token=REDACTED')
    .replace(/access_token=[^&]*/, 'access_token=REDACTED')
    .replace(/state=[^&]*/, 'state=REDACTED')

datadogRum.init({
  clientToken: 'pub974edea1e358c791122e7cb43a7d71ee',
  applicationId: 'd8e064d5-2c2f-45fa-92ac-bf0e6eb963ec',
  site: 'datadoghq.com',
  service: '360-training-frontend',
  env: process.env.SERVICE_ENV,
  version: process.env.GIT_COMMIT,
  sampleRate: 100,
  trackInteractions: true,
  beforeSend: event => {
    event.view.url = redactSensitiveInfo(event.view.url)

    if (event.view.referrer) {
      event.view.referrer = redactSensitiveInfo(event.view.referrer)
    }

    const resourceEvent = event as RumResourceEvent
    if (resourceEvent.resource) {
      resourceEvent.resource.url = redactSensitiveInfo(resourceEvent.resource.url)
    }

    const errorEvent = event as RumErrorEvent
    if (errorEvent.error && errorEvent.error.resource) {
      errorEvent.error.resource.url = redactSensitiveInfo(errorEvent.error.resource.url)
    }
  }
})

export default datadogRum
