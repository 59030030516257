import { client } from 'lib/client/api'
import { GetAccessTokenFunc, LogoutFunc, SignOutReason } from 'lib/hooks/useAuth'
import createDebug from 'lib/util/createDebug'
import { reportError } from 'lib/util/errorHandler'
import { Self } from 'lib/util/self'
import { useEffect, useState } from 'react'

import { Plan, Role, Status } from './subscription'
import { Urls } from './urls'

const debug = createDebug('subscription')

const buildAuthHeaders = (accessToken: string) => ({
  Accept: 'application/json',
  Authorization: `Bearer ${accessToken}`
})

function findFirstRoleOfType(roles: Role[], state: Status, plan: Plan) {
  const rolesOfType = roles.filter(role => role.state === state && role.plan === plan)
  if (rolesOfType && rolesOfType.length > 0) {
    return rolesOfType[0]
  }

  return null
}

function findPrimaryAdminRole(roles: Role[]): Role {
  return (
    findFirstRoleOfType(roles, 'active', 'team') ||
    findFirstRoleOfType(roles, 'inactive', 'team') ||
    findFirstRoleOfType(roles, 'active', 'trial') ||
    findFirstRoleOfType(roles, 'inactive', 'trial')
  )
}

export default function useSubscription(getAccessToken: GetAccessTokenFunc, logout: LogoutFunc, self: Self) {
  const { loading: userLoading, id: userId } = self
  const [userSubStatus, setUserSubStatus] = useState({ subscription: null, adminRole: null, loading: true })

  useEffect(() => {
    if (!userId) {
      debug('No user id; setting subscription status to "not logged in/no sub at all"')
      // - Handles initial case where userId is null since self is still loading
      // - Also handles cases where user is later logged out
      setUserSubStatus({ subscription: null, adminRole: null, loading: userLoading })

      return
    }
    debug(`Have a user id (${userId}); getting access token`)
    getAccessToken()
      .then(accessToken => {
        debug('Got access token; fetching subscription status')

        return client
          .get(Urls.STATUS_ENDPOINT, { headers: buildAuthHeaders(accessToken) })
          .then(res => setUserSubStatus({ subscription: res.data, adminRole: null, loading: false }))
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          debug('Got back 404; fetching subscription roles')

          return getAccessToken().then(accessToken =>
            client.get(Urls.ROLES_ENDPOINT, { headers: buildAuthHeaders(accessToken) }).then(res => {
              const adminRoles = [...res.data['additional-admin'], ...res.data['admin']]
              if (!adminRoles || adminRoles.length < 1) {
                debug('No admin roles; setting subscription status to "not logged in/no sub at all"')
                setUserSubStatus({ subscription: null, adminRole: null, loading: false })
              } else {
                const adminRole = findPrimaryAdminRole(adminRoles)
                debug('Setting subscription status to non-seat-holding admin', { adminRole })
                setUserSubStatus({
                  adminRole,
                  subscription: null,
                  loading: false
                })
              }
            })
          )
        } else {
          debug('Unexpected error fetching subscription status; logging out', error)
          logout(SignOutReason.StatusError, `Error while fetching status: ${error}`)
          reportError(error, 'Error fetching subscription status', { userId })
        }
      })
      .catch(error => {
        debug('Unexpected error fetching subscription roles; logging out', error)
        logout(SignOutReason.RoleError, `Error fetching subscription roles: ${error}`)
        reportError(error, 'Error fetching subscription roles', { userId })
      })
  }, [getAccessToken, logout, userId, userLoading])

  return userSubStatus
}
