import { isAdmin, Self } from 'lib/util/self'
import { useEffect, useState } from 'react'

const ADMIN_MODE_STORAGE_KEY = 'admin-mode'
const HIDE_PAST_WEBINARS_STORAGE_KEY = 'admin-hide-past-webinars'

function getStoredValue(key: string): boolean {
  return localStorage.getItem(key) === 'true'
}

function setStoredValue(key: string, isEnabled: boolean) {
  localStorage.setItem(key, isEnabled.toString())
}

export default function useAdmin(self: Self) {
  const [showAdminControls, setShowAdminControls] = useState(getStoredValue(ADMIN_MODE_STORAGE_KEY))
  const [hidePastWebinars, setHidePastWebinars] = useState(getStoredValue(HIDE_PAST_WEBINARS_STORAGE_KEY))
  const toggleAdminControls = () => setShowAdminControls(current => !current)

  useEffect(() => {
    setStoredValue(ADMIN_MODE_STORAGE_KEY, showAdminControls)
    setStoredValue(HIDE_PAST_WEBINARS_STORAGE_KEY, hidePastWebinars)
  })

  return {
    showAdminToggle: isAdmin(self),
    showAdminControls: isAdmin(self) && showAdminControls,
    hidePastWebinars,
    setHidePastWebinars,
    toggleAdminControls
  }
}
