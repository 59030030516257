import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { GetAccessTokenFunc } from 'lib/hooks/useAuth'
import { v4 as uuid } from 'uuid'

import createDebug from './createDebug'
import { reportError } from './errorHandler'

const ANONYMOUS_ID_KEY = 'analytics-anonymous-id'
const debug = createDebug('analytics')

interface AidJwt {
  aid?: string
}

function safeJwtDecode(rawToken: string): AidJwt {
  if (!rawToken) {
    return {}
  }

  try {
    return jwtDecode(rawToken)
  } catch (err) {
    return {}
  }
}

function hasAnonymousId(): boolean {
  return localStorage.getItem(ANONYMOUS_ID_KEY) !== null
}

function getAnonymousId(generateIfNotSet: boolean): string {
  if (hasAnonymousId()) {
    return localStorage.getItem(ANONYMOUS_ID_KEY)
  }

  if (!generateIfNotSet) {
    return undefined
  }

  debug('generating new anonymoous id')
  const anonymousId = uuid()
  localStorage.setItem(ANONYMOUS_ID_KEY, anonymousId)

  return anonymousId
}

export function clearAnonymousId(): void {
  debug('clearing anonymoous id')
  localStorage.removeItem(ANONYMOUS_ID_KEY)
}

export const events = {
  registrationLinkClicked: 'Registration Link Clicked',
  removingSessionCookie: 'Removing Session Cookie',
  search: 'Search',
  signedIn: 'Signed In',
  unexpectedLogout: 'Unexpected Logout',
  videoClicked: 'Video Clicked',
  webinarClicked: 'Webinar Clicked',
  webinarObjectiveClicked: 'Webinar Objective Clicked',
  webinarPlayed: 'Webinar Played'
}

export async function track(
  event: string,
  properties: object,
  getAccessToken: GetAccessTokenFunc,
  showAdminControls: boolean
) {
  let accessToken
  let headers = {}

  try {
    accessToken = await getAccessToken()
    headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined
    // eslint-disable-next-line no-empty
  } catch (err) {} // JWT auth is optional

  const anonymousIdRequired = !accessToken // anonymous event tracking requires anonymousId
  const data = {
    anonymousId: getAnonymousId(anonymousIdRequired),
    event,
    properties
  }

  if (showAdminControls) {
    debug(
      `NOT tracking "${event}" event for ${accessToken ? 'logged in' : 'anonymous'} user (currently in admin mode)`,
      data
    )

    return
  }

  try {
    debug(`tracking "${event}" event for ${accessToken ? 'logged in' : 'anonymous'} user`, data)
    await axios.post(`${process.env.THREESIXTY_TRAINING_URI}/analytics`, data, { headers })
  } catch (err) {
    reportError(err, 'Error tracking analytics event', {
      ...data,
      userId: safeJwtDecode(accessToken).aid || '[not logged in]'
    })
  }
}

export async function identify(accessToken: string) {
  if (!hasAnonymousId()) {
    debug('identify returning early (no anonymous id => nothing to identify)')

    return
  }

  const headers = { Authorization: `Bearer ${accessToken}` }
  const data = {
    anonymousId: getAnonymousId(false),
    event: 'Identify'
  }

  try {
    debug('identifying anonymous user', data)
    await axios.post(`${process.env.THREESIXTY_TRAINING_URI}/analytics`, data, { headers })
    clearAnonymousId()
  } catch (err) {
    reportError(err, 'Error identifying analytics user', {
      ...data,
      userId: safeJwtDecode(accessToken).aid || '[not logged in]'
    })
  }
}

export async function incrementWebinarViewCount(recordedWebinarId: string, showAdminControls: boolean): Promise<void> {
  if (showAdminControls) {
    debug(`NOT incrementing view count for recorded webinar ${recordedWebinarId} (currently in admin mode)`)

    return
  }
  try {
    debug(`incrementing view count for recorded webinar ${recordedWebinarId}`)
    await axios.post(`${process.env.THREESIXTY_TRAINING_URI}/webinars/${recordedWebinarId}/viewed`)
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      reportError(error, 'Error incrementing recorded webinar view count', { recordedWebinarId })
    }
  }
}
