import './Spinner.scss'

import classNames from 'classnames'
import React, { HTMLAttributes } from 'react'

export interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  size?: 'large' | 'medium' | 'small'
}

function Spinner(props: SpinnerProps): JSX.Element {
  const { className, size = 'small', ...otherProps } = props
  const mergedClassName = classNames('spinner', className, {
    'spinner--large': size === 'large',
    'spinner--medium': size === 'medium',
    'spinner--small': size === 'small'
  })

  return (
    <div className={mergedClassName} {...otherProps}>
      <div className="spinner__dot spinner__dot--first" />
      <div className="spinner__dot spinner__dot--second" />
      <div className="spinner__dot" />
    </div>
  )
}

export default Spinner
