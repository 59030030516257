import './NotFound.scss'

import TelescopeImg from 'img/error-telescope.png'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorPage from './ErrorPage'

export default function NotFound() {
  const [t] = useTranslation()

  return (
    <ErrorPage className="not-found">
      <ErrorPage.ArticulateLogo />
      <ErrorPage.Header />
      <ErrorPage.Description>{t('errorPages.notFound.description')}</ErrorPage.Description>
      <ErrorPage.CustomImage src={TelescopeImg} alt={t('errorPages.imgAlt')} />
      <ErrorPage.BackButton />
      <ErrorPage.HelpLink />
    </ErrorPage>
  )
}
