import axios from 'axios'
import { add } from 'date-fns'
import { reportError } from 'lib/util/errorHandler'

const DOMAIN = process.env.SESSION_COOKIE_DOMAIN

const calculateDefaultExpiration = () => add(new Date(), { months: 6 })

function buildCookie(name: string, value: string, expires?: Date): string {
  const expiration = expires ? `expires=${expires}; ` : ''

  return `${name}=${value}; ${expiration}domain=${DOMAIN}; path=/; secure=true`
}

export function getCookie(name: string) {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length)
    }
  }

  return null
}

export function removeCookie(name: string) {
  document.cookie = `${buildCookie(name, '')}; Max-Age=-99999999`
}

export function setCookie(name: string, value: string, expires?: Date) {
  document.cookie = buildCookie(name, value, expires)
}

export function setPersistantCookie(name: string, value: string) {
  setCookie(name, value, calculateDefaultExpiration())
}

export function getUserCountryCookie() {
  return getCookie(process.env.USER_COUNTRY_COOKIE_NAME)
}

export async function saveUserCountryCookie() {
  try {
    const { data } = await axios.get(process.env.GEO_URI, { headers: { Accept: 'application/json' } })

    if (data?.threeCharCountry) {
      document.cookie = `${process.env.USER_COUNTRY_COOKIE_NAME}=${data.threeCharCountry}; domain=${process.env.USER_COUNTRY_COOKIE_DOMAIN}`
    }
  } catch (error) {
    reportError(error, `Error saving ${process.env.USER_COUNTRY_COOKIE_NAME} cookie`)
  }
}
