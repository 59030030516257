import React from 'react'

// Intentionally cause a rendering error so that
// we can test our error handling
export default function RenderingError() {
  let obj: string | null

  // eslint-disable-next-line prefer-const
  obj = null

  obj.length

  return <div />
}
