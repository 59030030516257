import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import { useSubscription } from 'lib/client/360-subscriptions'
import AuthContext from 'lib/context/AuthContext'
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react'

interface LaunchDarklyProviderProps {
  children: ReactNode
}

interface LaunchDarklyConsumerProps {
  children: ReactNode
}

const isStageOrDevEnv = (env: string) => {
  return env === 'dev' || env === 'stage' || (env && env.startsWith('peer'))
}

const anonUser = '00000000-0000-0000-0000-000000000000'
const stageAnonHash = '97fc647bf61508ffec2fd8e56508b735b4151be3c32f194fb86fd0ee01814ae2'
const prodAnonHash = '0af75a0501aac24e83ea7784367b25625eec761e13480c08bdc0b16084f0a4af'
const anonHash = isStageOrDevEnv(process.env.SERVICE_ENV) ? stageAnonHash : prodAnonHash

const ldInitializationOptions = {
  clientSideID: process.env.LAUNCHDARKLY_CLIENT_SIDE_ID,
  deferInitialization: true
}

function useLDUser() {
  const { self, getAccessToken, logout } = useContext(AuthContext)
  const { subscription } = useSubscription(getAccessToken, logout, self)

  const anonymous = !self?.id
  const hash = anonymous ? anonHash : self?.hash

  return useMemo(() => {
    const context = {
      anonymous,
      kind: 'user',
      key: anonymous ? anonUser : self?.id,
      subscriptionId: subscription?.subscription_id
    }

    return { context, hash }
  }, [hash, self, subscription])
}


function LDHandler({ children }: LaunchDarklyConsumerProps) {
  const client = useLDClient()
  const [identifyPromise, setIdentifyPromise] = useState(null)
  const { context, hash } = useLDUser()

  useEffect(() => {
    (async() => {
      if (identifyPromise) {
        await identifyPromise
        setIdentifyPromise(null)

        return
      }
      setIdentifyPromise(client?.identify(context, hash))
    })()
  }, [context, hash, client])

  return (
    <>{children}</>
  )
}


export default function({ children }: LaunchDarklyProviderProps) {
  const { context, hash } = useLDUser()

  return (
    <LDProvider {...ldInitializationOptions} options={{ hash }} context={context}>
      <LDHandler children={children} />
    </LDProvider>
  )
}
