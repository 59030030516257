import { History, Pathname } from 'history'
import { reportError } from 'lib/util/errorHandler'
import qs from 'qs'
import { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { isLoginRequiredError, isNoTokenError } from './errors'
import { authClient, signInNoPrompt } from './okta'
import { OktaError, SetupLocalSessionFunc } from './types'

const defaultPath = '/'

const getStoredPath = (currentPath: Pathname, pathKey: string) => {
  const storedPath = window.localStorage.getItem(pathKey) || defaultPath

  return storedPath === currentPath ? defaultPath : storedPath
}

const redirect = (history: History, currentPath: Pathname, pathKey: string) => {
  const redirectPath = getStoredPath(currentPath, pathKey)
  window.localStorage.removeItem(pathKey)
  history.replace(redirectPath)
}

const handleNoTokens = (error: OktaError) => {
  if (isNoTokenError(error)) {
    return signInNoPrompt()
  }
  throw error
}

const handleNotLoggedIn = (error: OktaError) => {
  if (isLoginRequiredError(error)) {
    return
  }
  throw error
}

export interface AuthCallbackProps extends RouteComponentProps {
  setupLocalSession: SetupLocalSessionFunc
}

export default withRouter(function AuthCallback(props: AuthCallbackProps) {
  const { history, location, setupLocalSession } = props

  useEffect(() => {
    const { state } = qs.parse(location.hash.slice(1))

    authClient.token
      .parseFromUrl()
      .catch(handleNoTokens)
      .then(setupLocalSession)
      .catch(handleNotLoggedIn)
      .catch((error: Error) => reportError(error, 'Error setting up local session'))
      .then(() => redirect(history, location.pathname, state as string))
  }, [history, location, setupLocalSession])

  return null
})
